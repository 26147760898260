<!-- 产品与服务 -->
<template>
    <div class="product_service" id="jumpPoint">        
        <banner-bar></banner-bar>

        <div class="content_area">
            <div class="per_block" v-for="(info,index) in infoes" :key="index">
                <div class="left">
                    <img :src="info.imgUrl" alt="">
                </div>
                <div class="right" @click="linkChildren(index)">
                    <div>
                        <div class="title">{{info.title}}</div>
                        <div class="line"></div>
                        <div class="content">{{info.content}}</div>
                    </div>
                    <div class="tip">查看详情 >></div>
                </div>
            </div>
            <span></span>
        </div>
    </div>
</template>

<script>
import bannerBar from './../../components/bannerBar/bannerBar.vue'
export default{
    name:'productService',
    components:{
        bannerBar,
    },
    data(){
        return {
            infoes:[
                {
                    imgUrl:require('./../../assets/productService/img1.png'),
                    title:'智慧养老系统',
                    content:'云谷智慧养老院服务管理平台是浙江云谷数据有限公司为养老机构、养老院、敬老院、养老福利院、老年公寓等养老服务机构量身定制开发的专业的养老APP软件产品；涵盖医疗服务、社区服务、养老机构、上门服务、助餐服务、老人活动参加、老人之间的社交老友圈等服务。',
                },
                {
                    imgUrl:require('./../../assets/productService/img2.png'),
                    title:'云上横河系统',
                    content:'方便用户为了景点能更加效率的了解情况，能对整个村之间的能更快了解项目建设情况。整个云上横河“码上说”就这么诞生了，云谷特意为此开发的系统。根据首页的地图了解大致的情况，在每个景点之间设置了二维码，更快流通各自的情况。',
                },
                {
                    imgUrl:require('./../../assets/productService/img3.png'),
                    title:'智慧党建平台',
                    content:'通过我们的车间党支部智慧党建系统平台促进党组织与车间员工间紧密联系，方便员工操作，能够及时解决员工的困难，确保党建工作与车间生 产深度融合，深化党支部在企业生产经营中的实质性助力作用。',
                },
                {
                    imgUrl:require('./../../assets/productService/img4.png'),
                    title:'MES系统',
                    content:'MES系统是一套面向制造企业车间执行层的生产信息化管理系统。MES可以为企业提供包括制造数据管理、计划排程管理、生产调度管理、库存管理、质量管理、人力资源管理、工作中心/设备管理、工具工装管理、采购管理、成本管理、项目看板管理、生产过程控制、底层数据集成分析、上层数据集成分解等管理模块，为企业打造一个扎实、可靠、全面、可行的制造协同管理平台。',
                },
            ]
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        linkChildren(index){
            // console.log("index:",index)
            if(index == 0){
                this.$router.push({
                    name:'Product1'
                })
            }else if(index == 1){
                this.$router.push({
                    name:'Product2'
                })
            }else if(index == 2){
                this.$router.push({
                    name:'Product3'
                })
            }else if(index == 3){
                this.$router.push({
                    name:'Product4'
                })
            }
        },

        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>

<style scoped lang='scss'>
@import './productService.scss'
</style>
